import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import React from "react";
var __jsx = React.createElement;
import { useState } from 'react';
import { Pressable, useWindowDimensions, View } from 'react-native';
import { useSelector } from 'react-redux';
import { WEB_CONTAINER_WIDTH } from '~/api/models/Constants';
import { selectIsEmbedded } from '~/common/slices/app.slice';
import { Container, SubTitle1, Theme, themed } from '~/styles/Theme';
var SegmentedControl = function SegmentedControl(props) {
  var firstSceneTitle = props.firstSceneTitle,
    secondSceneTitle = props.secondSceneTitle,
    firstScene = props.firstScene,
    secondScene = props.secondScene,
    _props$initialSegment = props.initialSegmentIndex,
    initialSegmentIndex = _props$initialSegment === void 0 ? 0 : _props$initialSegment,
    onSegmentChanged = props.onSegmentChanged;
  var _useState = useState(initialSegmentIndex),
    index = _useState[0],
    setIndex = _useState[1];
  var _useWindowDimensions = useWindowDimensions(),
    width = _useWindowDimensions.width;
  var isEmbedded = useSelector(selectIsEmbedded);
  function onSegmentPressed(index) {
    return function () {
      onSegmentChanged === null || onSegmentChanged === void 0 || onSegmentChanged(index);
      setIndex(index);
    };
  }
  return __jsx(View, {
    style: {
      flex: 1
    }
  }, __jsx(SegmentContainer, {
    isEmbedded: isEmbedded
  }, __jsx(SegmentBar, null, __jsx(Pressable, {
    style: {
      width: '50%'
    },
    onPress: onSegmentPressed(0),
    testID: "servicesTab"
  }, __jsx(SegmentView, {
    selected: index === 0,
    testID: TEST_IDS.firstSegment
  }, __jsx(SegmentText, {
    selected: index === 0
  }, firstSceneTitle))), __jsx(Pressable, {
    style: {
      width: '50%'
    },
    onPress: onSegmentPressed(1),
    testID: "staffMembersTab"
  }, __jsx(SegmentView, {
    selected: index === 1,
    testID: TEST_IDS.secondSegment
  }, __jsx(SegmentText, {
    selected: index === 1
  }, secondSceneTitle))))), __jsx(View, {
    style: {
      flex: 1
    }
  }, __jsx(SceneContainer, {
    width: width
  }, index === 0 ? firstScene() : secondScene())));
};
var componentName = 'SegmentedControl';
export var TEST_IDS = {
  firstSegment: "".concat(componentName, "FirstSegment"),
  secondSegment: "".concat(componentName, "SecondSegment")
};
export default SegmentedControl;
var SegmentContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", "\n\n  padding: 15px\n  backgroundColor: white\n  shadow-color: #ccc\n  shadow-offset: 2px 5px\n  shadow-opacity: 0.2\n  shadow-radius: 4px\n"])), function (props) {
  return !props.isEmbedded && "\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n  ";
});
var SceneContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-grow: 1\n  flex-shrink: 1\n  flex-basis: 0%\n  ", "\n  max-width: ", "px\n"])), function (props) {
  return "width: ".concat(props.width, "px");
}, WEB_CONTAINER_WIDTH);
var SegmentBar = themed(Container)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  height: 40px\n  ", "\n  border-width: 1px\n  border-radius: 100px\n  overflow: hidden\n  flex-direction: row\n"])), function (props) {
  return "border-color: ".concat(Theme(props).colors.main);
});
var SegmentView = themed(Container)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  ", "\n  height: 40px\n  align-items: center\n  justify-content: center\n  width: 100%\n"])), function (props) {
  return "background-color: ".concat(props.selected ? Theme(props).colors.main : 'white');
});
var SegmentText = themed(SubTitle1)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  ", "\n  margin-bottom: 5px;\n"])), function (props) {
  return "color: ".concat(props.selected ? 'white' : Theme(props).colors.main);
});
import React from "react";
var __jsx = React.createElement;
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { staffs as staffsResource } from '~/api/Resources';
import { fetchResource } from '~/common/network/network';
import { selectBranchId } from '~/common/slices/app.slice';
import { setPreferredStaff } from '~/common/slices/cart.slice';
import ErrorScreen from '~/components/ErrorScreen';
import LoadingView from '~/components/LoadingView';
import StaffList from '~/components/StaffList';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import Tally from './components/tally/Tally';
var StaffMembersScreen = function StaffMembersScreen() {
  var dispatch = useDispatch();
  var branchId = useSelector(selectBranchId);
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var _useQuery = useQuery([staffsResource.path, branchId], function () {
      return fetchResource(staffsResource, {
        order: '+internet_pos'
      });
    }),
    data = _useQuery.data,
    isLoading = _useQuery.isLoading,
    isError = _useQuery.isError,
    refetch = _useQuery.refetch;
  if (isLoading) {
    return __jsx(LoadingView, null);
  }
  if (isError) {
    return __jsx(ErrorScreen, {
      onPress: refetch
    });
  }
  function onStaffSelection(staff) {
    dispatch(setPreferredStaff(staff));
    push(getRouteUrl(RouteName.Services, {
      staffId: staff.id
    }));
  }
  return __jsx(React.Fragment, null, __jsx(StaffList, {
    staffs: data,
    onStaffSelection: onStaffSelection
  }), __jsx(Tally, null));
};
export default StaffMembersScreen;
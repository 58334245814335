import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5;
import React from "react";
var __jsx = React.createElement;
import { Pressable, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { isMultiBranch } from '~/api/models/ModelFunctions';
import { Config } from '~/common/Config';
import Localize from '~/common/Localize';
import { AnalyticsEvents } from '~/common/analytics/Analytics';
import useAnalytics from '~/common/hooks/analytics/useAnalytics';
import useVisualMode from '~/common/hooks/useVisualMode';
import { selectEnv } from '~/common/slices/app.slice';
import { FeatureFlagsEnum, selectBranch, selectBusiness, selectFeatureFlag } from '~/common/slices/model.slice';
import { selectIsUserLoggedIn } from '~/common/slices/user.slice';
import { onLinkPress } from '~/components/OpenLink';
import { RouteName, getRouteUrl } from '~/navigation/Routes';
import { NotificationBanner, showNotificationBanner } from '~/screens/home/HomeComponents';
import { Container, SubTitle2, Theme, themed } from '~/styles/Theme';
var Voucher = function Voucher(props) {
  var _props$branch;
  var analytics = useAnalytics();
  if ((_props$branch = props.branch) !== null && _props$branch !== void 0 && _props$branch.onlineGiftCardsEnabled) {
    var url = "".concat(Config.voucherURL(props.env), "/").concat(props.branch.domainName);
    var label = props.country.toUpperCase() === 'US' || props.country.toUpperCase() === 'AU' ? 'giftcard.title' : 'voucher.title';
    return __jsx(EmbeddedButton, {
      onPress: function onPress() {
        analytics.logAnalyticsEvent(AnalyticsEvents.LinkOutToVouchers);
        onLinkPress(url);
      },
      testID: "voucherButton"
    }, __jsx(EmbeddedButtonText, null, Localize(label)), __jsx(Icon, {
      name: "arrow-up-right",
      color: "#505E75",
      size: 18,
      style: {
        marginLeft: 8
      }
    }));
  }
  return null;
};
var Shop = function Shop(props) {
  var _props$branch2, _props$branch3;
  var analytics = useAnalytics();
  var enabled = (_props$branch2 = props.branch) === null || _props$branch2 === void 0 || (_props$branch2 = _props$branch2.ecommerceSettings) === null || _props$branch2 === void 0 ? void 0 : _props$branch2.onlineStoreEnabled;
  var url = (_props$branch3 = props.branch) === null || _props$branch3 === void 0 || (_props$branch3 = _props$branch3.ecommerceSettings) === null || _props$branch3 === void 0 ? void 0 : _props$branch3.onlineStoreUrl;
  if (enabled && url) {
    return __jsx(EmbeddedButton, {
      onPress: function onPress() {
        analytics.logAnalyticsEvent(AnalyticsEvents.HomeShop);
        onLinkPress(url);
      },
      testID: "shopButton"
    }, __jsx(EmbeddedButtonText, null, Localize('home.shop')), __jsx(Icon, {
      name: "arrow-up-right",
      color: "#505E75",
      size: 18,
      style: {
        marginLeft: 8
      }
    }));
  }
  return null;
};
var ContactUs = function ContactUs() {
  var analytics = useAnalytics();
  var _useRouter = useRouter(),
    push = _useRouter.push;
  return __jsx(EmbeddedButton, {
    onPress: function onPress() {
      analytics.logAnalyticsEvent(AnalyticsEvents.HomeContactUs);
      push(getRouteUrl(RouteName.ConsultationsForm));
    },
    testID: "contactUsButton"
  }, __jsx(EmbeddedButtonText, null, Localize('leads.sections.contactUs')), __jsx(Icon, {
    name: "arrow-up-right",
    color: "#505E75",
    size: 18,
    style: {
      marginLeft: 8
    }
  }));
};
export function EmbeddedServicesHeader() {
  var _business$locale;
  var business = useSelector(selectBusiness);
  var env = useSelector(selectEnv);
  var branch = useSelector(selectBranch);
  var isUserLoggedIn = useSelector(selectIsUserLoggedIn);
  var visualMode = useVisualMode();
  var country = (business === null || business === void 0 || (_business$locale = business.locale) === null || _business$locale === void 0 ? void 0 : _business$locale.country) || 'IE';
  var _useRouter2 = useRouter(),
    push = _useRouter2.push;
  var locationSelectorEnabled = useSelector(selectFeatureFlag(FeatureFlagsEnum.LocationSelector));
  var multiBranch = business ? isMultiBranch(business) : false;
  return __jsx(View, {
    testID: "embeddedServicesHeader"
  }, branch && showNotificationBanner(branch, visualMode) ? __jsx(NotificationBannerContainer, {
    testID: "notificationBanner"
  }, __jsx(NotificationBanner, {
    branch: branch
  })) : null, __jsx(EmbeddedHeaderContainer, null, multiBranch && locationSelectorEnabled ? __jsx(EmbeddedButton, {
    onPress: function onPress() {
      return push(getRouteUrl(RouteName.Locations));
    },
    testID: "locationSelectorButton"
  }, __jsx(Icon, {
    name: "map-pin",
    color: "#505E75",
    size: 18,
    style: {
      marginRight: 8
    }
  }), __jsx(EmbeddedButtonText, null, branch === null || branch === void 0 ? void 0 : branch.name), __jsx(BranchSelectButtonText, null, Localize('guestAccount.branch'))) : null, __jsx(EmbeddedButton, {
    onPress: function onPress() {
      isUserLoggedIn ? push(getRouteUrl(RouteName.Appointments)) : push(getRouteUrl(RouteName.Login, {
        onLogin: RouteName.Appointments
      }));
    },
    testID: "appointmentHistoryButton"
  }, __jsx(EmbeddedButtonText, null, Localize('header.rebookAnAppointment'))), __jsx(Voucher, {
    branch: branch,
    country: country,
    env: env
  }), __jsx(Shop, {
    branch: branch
  }), branch && branch.leadManagementEnabled && __jsx(ContactUs, null)));
}
export default EmbeddedServicesHeader;
var EmbeddedHeaderContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 16px 16px 8px 16px\n  ", "\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
});
var EmbeddedButton = themed(Pressable)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: row\n  align-items: center\n  padding: 8px 16px\n  margin-bottom: 8px\n  height: 32px\n  border-width: 1px\n  border-color: #BAC2D0\n  border-style: solid\n  border-radius: 4px\n"])));
var EmbeddedButtonText = themed(SubTitle2)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  font-size: 12px\n"])));
var BranchSelectButtonText = themed(EmbeddedButtonText)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  margin-left: auto\n  color: #009CD4\n"])));
var NotificationBannerContainer = themed(Container)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 16px;\n  ", "\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.main);
});
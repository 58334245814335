import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createParam } from 'solito';
import Localize from '~/common/Localize';
import { selectPreferredStaff, setPreferredStaff } from '~/common/slices/cart.slice';
import ErrorScreen from '~/components/ErrorScreen';
import LoadingView from '~/components/LoadingView';
import StaffInfoHeader from '~/components/StaffInfoHeader';
import { RouteName } from '~/navigation/Routes';
import ServicesList from './components/ServiceList';
import Tally from './components/tally/Tally';
import { useServices } from './useServices';
import useServicesFunctions from './useServicesFunctions';
export var ServicesScreen = function ServicesScreen(props) {
  var _createParam = createParam(),
    useParam = _createParam.useParam;
  var dispatch = useDispatch();
  var showSpecialOffers = props.showSpecialOffers;

  // if present represents the preferred staff id
  var _useParam = useParam('staffId'),
    _useParam2 = _slicedToArray(_useParam, 1),
    preferredStaffId = _useParam2[0];
  var _useState = useState(''),
    filter = _useState[0],
    setFilter = _useState[1];
  var preferredStaff = useSelector(selectPreferredStaff);
  var _useServices = useServices({
      showSpecialOffers: showSpecialOffers,
      filter: filter,
      preferredStaffId: preferredStaffId !== null && preferredStaffId !== void 0 ? preferredStaffId : preferredStaff === null || preferredStaff === void 0 ? void 0 : preferredStaff.id
    }),
    newPreferredStaff = _useServices.preferredStaff,
    isError = _useServices.isError,
    isLoading = _useServices.isLoading,
    retry = _useServices.retry,
    viewModel = _useServices.viewModel;
  useEffect(function () {
    if (preferredStaffId && (preferredStaff === null || preferredStaff === void 0 ? void 0 : preferredStaff.id) !== preferredStaffId) {
      dispatch(setPreferredStaff(newPreferredStaff));
    }
  }, [preferredStaffId, preferredStaff, newPreferredStaff]);
  var _useServicesFunctions = useServicesFunctions(),
    onServicePress = _useServicesFunctions.onServicePress,
    onDescriptionPress = _useServicesFunctions.onDescriptionPress;
  if (isError) {
    return __jsx(ErrorScreen, {
      onPress: retry
    });
  }
  if (isLoading || !viewModel) {
    return __jsx(LoadingView, {
      title: Localize('loading.services')
    });
  }
  return __jsx(View, {
    testID: "servicesScreen",
    style: {
      flex: 1
    }
  }, preferredStaff && __jsx(StaffInfoHeader, {
    staff: preferredStaff
  }), __jsx(ServicesList, {
    viewModel: viewModel,
    preferredStaff: preferredStaff,
    onServicePress: onServicePress,
    onDescriptionPress: onDescriptionPress,
    filter: filter,
    setFilter: setFilter,
    searchEnabled: true
  }), __jsx(Tally, {
    preferredStaff: preferredStaff,
    viewModel: viewModel
  }));
};
ServicesScreen.displayName = RouteName.Services;
export default ServicesScreen;
import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2;
import React from "react";
var __jsx = React.createElement;
import { ScrollView } from 'react-native';
import { isWeb } from '~/common/Env';
import useBreakpoints from '~/common/hooks/useBreakpoints';
import ListItem from '~/components/ListItem';
import ProfileImage, { ProfileImageSize } from '~/components/ProfileImage';
import { Container, themed } from '~/styles/Theme';
import ErrorScreen from './ErrorScreen';
var StaffList = function StaffList(props) {
  var staffs = props.staffs;
  var _useBreakpoints = useBreakpoints(),
    isMobile = _useBreakpoints.isMobile;
  var getValidStaff = function getValidStaff(allStaff) {
    var validStaff = allStaff.staffs.filter(function (staff) {
      return !staff.hiddenFromOnlineBookings;
    });
    return validStaff.map(function (staff) {
      var testID = "staffMemberButton_".concat(staff.id);
      return __jsx(StaffItemContainer, {
        isMobile: isMobile,
        key: "container-".concat(staff.id)
      }, __jsx(ListItem, {
        key: staff.id,
        image: __jsx(ProfileImage, {
          imageUrl: staff.imageUrl500,
          size: ProfileImageSize.Small
        }),
        title: staff.firstName,
        subtitle: staff.category.name,
        onPress: function onPress() {
          return props.onStaffSelection(staff);
        },
        testID: testID,
        showChevron: true
      }));
    });
  };
  if (staffs) {
    return __jsx(ScrollView, {
      style: {
        flex: 1
      }
    }, __jsx(StaffContainer, {
      testID: "staffContainer",
      key: 'staffContainer'
    }, getValidStaff(staffs)));
  }
  return __jsx(ErrorScreen, {
    onPress: function onPress() {
      console.log('TODO handle error');
      // execute({ resource: staffs })
    }
  });
};
export default StaffList;
var StaffContainer = themed(Container)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  padding: 5px 20px 0 20px;\n  flex-direction: row;\n  flex-wrap: wrap;\n  align-items: stretch;\n"])));
var StaffItemContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  width: ", "\n  ", "\n  align-self: stretch;\n"])), function (props) {
  return props.isMobile ? '100%' : '50%';
}, function () {
  return isWeb() ? 'padding: 6px;' : '';
});
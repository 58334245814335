import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { CartFunctions } from '~/api/models/CartFunctions';
import { addCartItem, CartItemType, removeCartItems, selectCartItems } from '~/common/slices/cart.slice';
import { getRouteUrl, RouteName } from '~/navigation/Routes';
import { isMultiOptionsServiceGroup, isServiceGroup } from './ServicesViewModel';
export function useServicesFunctions() {
  var _useRouter = useRouter(),
    push = _useRouter.push;
  var dispatch = useDispatch();
  var cartItems = useSelector(selectCartItems);
  function onServicePress(selected, serviceViewModel, preferredStaff) {
    if (isServiceGroup(serviceViewModel)) {
      if (isMultiOptionsServiceGroup(serviceViewModel)) {
        updateCartForMultiChoiceServiceGroup(selected, serviceViewModel);
      } else {
        updateCartForNoChoiceServiceGroup(selected, serviceViewModel, preferredStaff);
      }
    } else {
      updateCartForSingleService(selected, serviceViewModel.originalService, preferredStaff);
    }
  }
  function onDescriptionPress(service) {
    if (isServiceGroup(service)) {
      var params = {
        groupId: service.id,
        title: service.name
      };
      push(getRouteUrl(RouteName.ServiceGroups, params));
    } else {
      var _params = {
        serviceId: service.id,
        title: service.name
      };
      push(getRouteUrl(RouteName.ServiceDetails, _params));
    }
  }
  function updateCartForMultiChoiceServiceGroup(selected, serviceViewModel) {
    if (selected) {
      push(getRouteUrl(RouteName.ServiceGroups, {
        groupId: serviceViewModel.id
      }));
    } else {
      removeCartItemWithId(CartFunctions.createCartId(serviceViewModel.id, CartItemType.ServiceGroup));
    }
  }
  function updateCartForNoChoiceServiceGroup(selected, serviceViewModel, preferredStaff) {
    if (selected) {
      var groupCartItem = CartFunctions.createNoChoiceServiceGroupCartItem(serviceViewModel, preferredStaff);
      dispatch(addCartItem(groupCartItem));
    } else {
      removeCartItemWithId(CartFunctions.createCartId(serviceViewModel.id, CartItemType.ServiceGroup));
    }
  }
  function updateCartForSingleService(selected, service, preferredStaff) {
    if (selected) {
      var item = CartFunctions.createServiceCartItem(service, preferredStaff);
      dispatch(addCartItem(item));
    } else {
      removeCartItemWithId(CartFunctions.createCartId(service.id, CartItemType.Service));
    }
  }
  function removeCartItemWithId(cartItemId) {
    var itemsToRemove = cartItems.filter(function (cartItem) {
      return cartItem.id.startsWith(cartItemId);
    });
    dispatch(removeCartItems(itemsToRemove));
  }
  return {
    onServicePress: onServicePress,
    onDescriptionPress: onDescriptionPress
  };
}
export default useServicesFunctions;
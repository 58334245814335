import _slicedToArray from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import React from "react";
var __jsx = React.createElement;
import { useDispatch, useSelector } from 'react-redux';
import { createParam } from 'solito';
import Localize from '~/common/Localize';
import { BookFrom, selectIsEmbedded, selectStartBookingFrom, setBookingFrom } from '~/common/slices/app.slice';
import { setPreferredStaff } from '~/common/slices/cart.slice';
import SegmentedControl from '~/components/SegmentedControl';
import { RouteName } from '~/navigation/Routes';
import ServicesScreen from './ServicesScreen';
import StaffMembersScreen from './StaffMembersScreen';
import EmbeddedServicesHeader from './components/EmbeddedServicesHeader';
export var ServiceSelectionScreen = function ServiceSelectionScreen() {
  var _createParam = createParam(),
    useParam = _createParam.useParam;
  var dispatch = useDispatch();
  var startBookingFrom = useSelector(selectStartBookingFrom);

  // if true opens up the special offer category by default
  var _useParam = useParam('showSpecialOffers'),
    _useParam2 = _slicedToArray(_useParam, 1),
    showSpecialOffers = _useParam2[0];
  var isEmbedded = useSelector(selectIsEmbedded);
  function onSegmentChanged(index) {
    dispatch(setPreferredStaff(undefined));
    dispatch(setBookingFrom(index === 0 ? BookFrom.Services : BookFrom.Staff));
  }
  function firstScene() {
    return __jsx(ServicesScreen, {
      showSpecialOffers: showSpecialOffers === 'true'
    });
  }
  function secondScene() {
    return __jsx(StaffMembersScreen, null);
  }
  return __jsx(React.Fragment, null, isEmbedded && __jsx(EmbeddedServicesHeader, null), __jsx(SegmentedControl, {
    firstSceneTitle: Localize('staffAndService.services'),
    secondSceneTitle: Localize('staffAndService.staff'),
    firstScene: firstScene,
    secondScene: secondScene,
    initialSegmentIndex: startBookingFrom === BookFrom.Services ? 0 : 1,
    onSegmentChanged: onSegmentChanged
  }));
};
ServiceSelectionScreen.displayName = RouteName.ServiceSelection;
export default ServiceSelectionScreen;
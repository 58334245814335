import _taggedTemplateLiteral from "/app/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/taggedTemplateLiteral.js";
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;
import React from "react";
var __jsx = React.createElement;
import isEmpty from 'lodash/isEmpty';
import truncate from 'lodash/truncate';
import { useMemo, useState } from 'react';
import { View } from 'react-native';
import Localize from '~/common/Localize';
import { Body3, Container, SubTitle1, Theme, themed } from '~/styles/Theme';
import ProfileImage, { ProfileImageSize } from './ProfileImage';
var MAX_TEXT_LENGHT = 100;
export var StaffInfoHeader = function StaffInfoHeader(_ref) {
  var _staff$onlineProfile;
  var staff = _ref.staff;
  var _useState = useState(true),
    truncateText = _useState[0],
    setFullText = _useState[1];
  var staffProfile = (_staff$onlineProfile = staff === null || staff === void 0 ? void 0 : staff.onlineProfile) !== null && _staff$onlineProfile !== void 0 ? _staff$onlineProfile : '';
  var profileText = useMemo(function () {
    return truncateText ? truncate(staffProfile, {
      length: MAX_TEXT_LENGHT
    }) : staffProfile;
  }, [truncateText, staff]);
  var showMoreButtonText = useMemo(function () {
    return truncateText ? Localize('staffProfile.showMore') : Localize('staffProfile.showLess');
  }, [truncateText, staff]);
  return __jsx(StaffContainer, {
    testID: "staffHeader"
  }, __jsx(StaffImage, null, __jsx(ProfileImage, {
    imageUrl: staff.imageUrl500,
    size: ProfileImageSize.Large
  })), __jsx(StaffInfo, null, __jsx(StaffName, {
    testID: "staffName"
  }, staff.firstName), __jsx(StaffJobTitle, {
    testID: "staffTitle"
  }, staff.category.name), !isEmpty(staffProfile) && __jsx(View, null, __jsx(StaffText, {
    testID: "staffProfileText"
  }, profileText, ' ', __jsx(ShowMore, {
    testID: "showMore",
    onPress: function onPress() {
      return setFullText(!truncateText);
    }
  }, showMoreButtonText)))));
};
export default StaffInfoHeader;
var ShowMore = themed(Body3)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  ", ";\n  padding-top: 10px;\n  width: 100px;\n"])), function (props) {
  return "color: ".concat(Theme(props).colors.info);
});
var StaffContainer = themed(Container)(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n  flex-shrink: 1\n  border-bottom-color: #c7ccd7;\n  border-bottom-width: 1px;\n  ", ";\n"])), function (props) {
  return "background-color: ".concat(Theme(props).colors.neutral);
});
var StaffName = themed(SubTitle1)(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  padding-top: 5px;\n  padding-bottom: 2px;\n"])));
var StaffJobTitle = themed(Body3)(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  color: #94989e;\n"])));
var StaffImage = themed(Container)(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  padding: 20px;\n"])));
var StaffInfo = themed(Container)(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  padding: 10px;\n  flex-shrink: 1\n"])));
var StaffText = themed(Body3)(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  padding-top: 10px;\n  color: black;\n"])));